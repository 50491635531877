import * as React from 'react'
import { Component } from 'react';
import {List, Icon, Row, Modal, Tooltip, Badge, Spin} from "antd-min";
import {GLGlobal, PaginationParams, GLAction, withRouter, GLUtil, connect} from "gl-commonui";
import { GSAdminLocale } from '../../locales/localeid';
import { lazyInject, TYPES, GSAdminAction, parseUrlToAnchor, isHtml, ListStates, DateHelper } from '../../util/index';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PathConfig } from '../../config/pathconfig';
import { INotificationService, NotificationModel } from '../../service/notifications/index';
import { NotificationDescription, NotificationTitle } from './components';
import { ListStatesRadios, ListStatesStore } from '@app/components';
import {StateType} from "@app/states";
import {getNumUnread} from "@app/states/notification";

interface NotificationsProps {
    numUnread: number,
    getNumUnread: () => void,
}

interface NotificationsStates {
    list: NotificationModel[]
    loading: boolean,
    listState: ListStates,
    notificationStatistics: {
        [id: string]: {
            loading: boolean,
            totalSeen: number,
            totalTargetUsers: number
        }
    }
}

const emptyNotificationStatistic = {loading: false, totalSeen: 0, totalTargetUsers: 0};

@withRouter
@connect(
    ({ notification: { numUnread } }: StateType) => ({ numUnread }), { getNumUnread }
)
export class NotificationManagePage extends Component<NotificationsProps & RouteComponentProps<any>, NotificationsStates> {
    @lazyInject(TYPES.INotificationService)
    service: INotificationService
    pagination: PaginationParams = new PaginationParams(1, 20)
    listStateStore: ListStatesStore;
    listCnts: any = {};
    constructor(props, context) {
        super(props, context);
        this.listStateStore = new ListStatesStore("notification-manage-list-states", "notification-manage-list-states");
        this.state = {
            list: [],
            loading: false,
            listState: this.listStateStore.getState(),
            notificationStatistics: {}
        };
    }
    componentDidMount() {
        this.getNotifications();
        this.props.getNumUnread();
    }
    getNotifications(current?) {
        this.pagination.current = current || this.pagination.current;
        this.setState({ loading: true });
        // GL-5537(School | Increase management visibility for notification)
        // add grid filter: All, Mine, Others
        // this.service.getItemsBy({ filterByCreator: true, ...this.pagination.toRequest() }).then(d => {
        const {includePendingInvite, disabled, offset, limit} = this.listStateStore.getUrlQuery(this.pagination.toRequest());
        const params = {
            filterByCreator: !includePendingInvite && !disabled,
            filterByOther: disabled,
            hideArchived: true,
            offset,
            limit
        }
        this.service.manageNotifications({ ...params }).then(d => {
            this.pagination.total = ListStatesStore.getStateCnt(d.totalCount, d.extraData.mineCount, this.state);
            this.listCnts.allCnt = d.totalCount;
            this.listCnts.activeCnt = d.extraData.mineCount;
            this.setState({
                list: d.data.map(d => {
                    d.title = parseUrlToAnchor(d.title);
                    d.description = isHtml(d.description) ? d.description : parseUrlToAnchor(d.description);
                    return d;
                }),
                loading: false,
                notificationStatistics: d.data.reduce((pre, cur) => ({...pre, [cur.id]: emptyNotificationStatistic}), {})
            });
        })
    }
    async getNotificationStatistics(notificationId) {
        const { totalSeen, totalTargetUsers } = await this.service.getNotificationStatistics(notificationId)
            .catch((error) => {
                let notificationStatistics = this.state.notificationStatistics;
                notificationStatistics[notificationId] = emptyNotificationStatistic;
                this.setState({notificationStatistics})
            });
        let notificationStatistics = this.state.notificationStatistics;
        notificationStatistics[notificationId] = notificationStatistics[notificationId].loading ?
            {loading: false, totalSeen, totalTargetUsers} :
            emptyNotificationStatistic;
        this.setState({ notificationStatistics });
    }
    renderListItemActions(id, status) {
        let actions = [];
        // if ((ContextHelper.isUserMatchRole(RoleName.systemAdmin)
        //     || ContextHelper.isUserMatchRole(RoleName.globalHead)
        //     || status != NotificationStatus.Approved)) {
        //         actions.push(<Icon type="edit" key="update" onClick={(e) => this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.NotificationEdit, { notificationId: id }) })} />);
        // }
        actions.push(<Icon type="edit" key="update" onClick={(e) => this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.NotificationEdit, { notificationId: id }) })} />);
        actions.push(
            <Icon type="delete" key="delete" onClick={(e) => Modal.confirm({
                title: GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationCreateDelete }),
                onOk: () => {
                    this.service.delete(id).then(() => {
                        const page = this.pagination.current;
                        this.getNotifications(this.state.list.length === 1 && page !== 1 ? page - 1 : page);
                    })
                }
            })} />
        );
        return actions;
    }

    contentTooltipNotification({loading, totalSeen, totalTargetUsers}) {
        let percentageSeen;
        if (totalTargetUsers == 0) {
            percentageSeen = 0;
        } else {
            percentageSeen = ((totalSeen * 100) / totalTargetUsers);
            if (Number.isInteger(percentageSeen)) {
                percentageSeen = percentageSeen;
            } else {
                percentageSeen = percentageSeen.toFixed(2)
            }
        }
        return (
            <Spin spinning={loading}>
                <p>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationTarget })} - {totalTargetUsers}</p>
                <p>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationSeenBy })} - {totalSeen} ({percentageSeen}%)</p>
            </Spin>
        )
    }

    getStatusTitle(id, status, titleText, startDate, totalSeen, totalTargetUsers) {
        let notificationStatistics = this.state.notificationStatistics;
        const title = <>
            <NotificationTitle content={{ title: titleText }} />
            &nbsp;<span>{startDate ? `(${DateHelper.formatDate2Local(startDate)})` : ''}</span>
            <Tooltip
                key={id}
                title={this.contentTooltipNotification(notificationStatistics[id])}
                placement="topLeft" overlayClassName="notification-popover"
                trigger="click"
                onVisibleChange={(visible) => {
                    if (visible) {
                        notificationStatistics[id].loading = true;
                        this.setState({ notificationStatistics }, () => this.getNotificationStatistics(id));
                    } else {
                        notificationStatistics[id] = emptyNotificationStatistic;
                        this.setState({ notificationStatistics });
                    }
                }}
            >
                <Icon type="info-circle" className="notifications__tooltip" />
            </Tooltip>
            </>;
        // GL-5537(School | Increase management visibility for notification)
        // remove approve status from ui
        // switch (status) {
        //     case NotificationStatus.Submitted:
        //         return <><Icon type="clock-circle-o" />{title}</>;
        //     case NotificationStatus.Approved:
        //         return <><Icon type="check-circle-o" />{title}</>;
        //     case NotificationStatus.Rejected:
        //         return <><Icon type="close-circle-o" />{title}</>;
        //     default:
        //         return title;
        // }
        return title;
    }
    renderListItem({ id, title, description, status, sticky, startDate, totalSeen, totalTargetUsers }) {
        return <List.Item actions={this.renderListItemActions(id, status)}>
            <List.Item.Meta
                title={this.getStatusTitle(id, status, title, startDate, totalSeen, totalTargetUsers)}
                description={<NotificationDescription content={description} />}
            />
        </List.Item>;
    }
    render() {
        const { list, loading } = this.state;
        const { numUnread } = this.props;
        return (
            <div className='notifications content-layout my-created-notifications'>
                <Row type="flex" justify="start" className="notifications__top notifications__top__tabs">
                    <div className="notifications__top__tabs__btn">
                        <Badge count={numUnread} offset={[-10, 10]}>
                            <Link to={PathConfig.Notifications}>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationCreateNotifications })}</Link>
                        </Badge>
                    </div>
                    <div className="notifications__top__tabs__btn notifications__top__tabs__btn--active">
                        <span>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationManageNotifications })}</span>
                    </div>
                </Row>
                <div className="page-content">
                    <div className="notifications-manage-header">
                        <ListStatesRadios
                            allCnt={this.listCnts.allCnt}
                            activeCnt={this.listCnts.activeCnt}
                            value={this.state.listState}
                            activeTextLocaleId={GSAdminLocale.NotificationManageListStatesMine}
                            inActiveTextLocaleId={GSAdminLocale.NotificationManageListStatesOthers}
                            onChange={(state) => {
                                this.setState({ listState: state });
                                this.listStateStore.setState(state);
                                (this.pagination as PaginationParams).current = 1;
                                this.getNotifications();
                        }}/>
                        <div className="add-notification">
                            <GLAction action={GSAdminAction.CreateNotification}>
                                <Link to={PathConfig.NotificationCreate}><Icon type="plus-circle-o" />{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationCreateAdd })}</Link>
                            </GLAction>
                        </div>
                    </div>
                    <List dataSource={list} locale={{ emptyText: GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationsNoData }) }} loading={loading} pagination={list.length > 0 ? {
                        ...this.pagination,
                        onChange: page => this.getNotifications(page),
                    } : null} renderItem={this.renderListItem.bind(this)}></List>
                </div>
            </div>
        )
    }
}
